
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    index: {
      required: true,
      default () {
        return 0
      },
      type: Number
    },
    categories: {
      required: true,
      default () {
        return []
      },
      type: Array
    }
  },

  data () {
    return {
      item: this.value,
      active: false,
      query: ''
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      items: 'tradein/items'
    })
  },

  watch: {
    value: {
      handler (val) {
        this.item = val
      },
      immediate: true
    }
  },

  methods: {
    toggle () {
      this.active = !this.active

      if (this.$refs.category) {
        this.$nextTick(() => {
          this.$refs.category.focus()
        })
      }
    },

    hide () {
      this.active = false
    },

    setCategory (category) {
      this.query = ''

      this.clearCategory()

      this.$store.dispatch('tradein/setCategory', {
        index: this.index,
        category
      })

      this.hide()

      this.getOptions()
    },

    clearCategory () {
      this.query = ''

      this.$store.dispatch('tradein/setCategory', {
        index: this.index,
        category: null
      })

      this.$store.dispatch('tradein/setCharacteristicValue', {
        index: this.index,
        characteristicValue: null
      })

      this.$store.dispatch('tradein/setCharacteristic', {
        index: this.index,
        characteristic: {
          id: null,
          title: null,
          values: []
        }
      })

      this.$store.dispatch('tradein/setProduct', {
        index: this.index,
        product: null
      })

      this.$store.dispatch('tradein/setProducts', {
        index: this.index,
        products: []
      })

      this.$store.dispatch('tradein/setOptions', {
        index: this.index,
        options: []
      })
    },

    getOptions () {
      if (this.item.category === null) {
        return
      }

      this.$axios.$get(`${this.$i18n.locale}/${this.country.iso_code}/tradein/options/${this.item.category.id}`)
        .then((res) => {
          res.data.forEach((t, index) => {
            res.data[index].value = null
          })

          this.$store.dispatch('tradein/setOptions', {
            index: this.index,
            options: res.data
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
