
import { mapGetters } from 'vuex'

export default {
  props: {
    hiddenMobile: {
      required: true,
      default () {
        return true
      },
      type: Boolean
    }
  },

  computed: {
    ...mapGetters({
      compare: 'compare/compare'
    })
  }
}
