
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  props: {
    value: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    product: {
      required: true,
      default () {
        return {}
      },
      type: Object
    },
    index: {
      required: true,
      default () {
        return 0
      },
      type: Number
    },
    optionIndex: {
      required: true,
      default () {
        return 0
      },
      type: Number
    }
  },

  data () {
    return {
      option: this.value,
      active: false
    }
  },

  computed: {
    ...mapGetters({
      country: 'country',
      currency: 'currency'
    })
  },

  watch: {
    value: {
      handler (val) {
        this.option = val
      },
      immediate: true
    }
  },

  methods: {
    hide () {
      this.active = false
    },

    dropdown () {
      this.active = !this.active
    },

    setValue (variant) {
      this.$store.dispatch('tradein/setOptionVariant', {
        index: this.index,
        optionIndex: this.optionIndex,
        variant
      })
      this.hide()
    },

    calculateAmount (percent) {
      return this.$n((this.product.price / 100 * percent).toFixed(this.currency.round))
    },

    clearOption () {
      this.$store.dispatch('tradein/setOptionVariant', {
        index: this.index,
        optionIndex: this.optionIndex,
        variant: null
      })
    }
  }
}
