

export default {
  props: {
    options: {
      type: Object,
      default () {
        return {
          root: null,
          threshhold: '0'
        }
      }
    }
  },

  data: () => ({
    observer: null
  }),

  mounted () {
    this.observer = new IntersectionObserver((entries) => {
      this.handleIntersect(entries[0])
    }, this.options)

    this.observer.observe(this.$refs.trigger)
  },

  destroyed () {
    this.observer.disconnect()
  },

  methods: {
    handleIntersect (entry) {
      if (entry.isIntersecting) {
        this.$emit('triggerIntersected')
      }
    }
  }
}
