
import { mapGetters } from 'vuex'

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  components: {
    Breadcrumbs
  },

  props: {
    category: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data: () => ({
    page: 1,
    // params: '',
    subcategoriesTouchScreen: true,
    subcategoriesTouchScreenActive: false
  }),

  computed: {
    ...mapGetters({
      country: 'country'
    }),

    breadcrumbs () {
      const length = this.category.breadcrumbs.length

      this.category.breadcrumbs.map((crumb, index) => {
        crumb.to = this.localePath({ name: 'slug', params: { slug: crumb.slug } })
        crumb.active = length === (index + 1)
      })

      return [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        }
      ].concat(this.category.breadcrumbs)
    }
  },

  beforeMount () {
    this.resizeWindow()
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizeWindow, { passive: true })
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },

  methods: {
    productsByCategory (category) {
      let length
      const categoriesLength = this.category.productsByCategory.length

      if (['mobileSmall', 'mobile'].includes(this.$mq)) {
        length = categoriesLength > 6 ? 4 : 8
      } else if (['tablet'].includes(this.$mq)) {
        length = categoriesLength > 6 ? 6 : 12
      } else if (['desktop'].includes(this.$mq)) {
        length = categoriesLength > 6 ? 9 : 12
      } else if (['widescreen'].includes(this.$mq)) {
        length = categoriesLength > 6 ? 8 : 12
      } else {
        length = 12
      }

      return category.products.slice(0, length)
    },

    isTouchScreen () {
      return document.documentElement.clientWidth <= 991
    },

    resizeWindow () {
      this.subcategoriesTouchScreen = this.isTouchScreen()
    },

    toggleAccordion (event) {
      const matches = document.querySelectorAll('.accordion-item-head')
      const isActive = event.target.classList.contains('active')

      for (let i = 0; i < matches.length; i++) {
        matches[i].classList.remove('active')
      }

      if (!isActive) {
        event.target.classList.add('active')
      }
    }
  }
}
