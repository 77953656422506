
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters({
      currency: 'currency'
    }),

    estimate () {
      let percent = 0

      if (this.item.options.length > 0) {
        percent = this.item.options
          .filter(o => o.value && o.value.percent)
          .reduce((sum, o) => sum + o.value.percent, 0)
      }

      if (percent === 0) {
        return this.item.product.price
      } else if (percent > 0 && percent < 100) {
        return (this.item.product.price - (this.item.product.price / 100 * percent)).toFixed(this.currency.round)
      } else {
        return 0
      }
    }
  },

  methods: {
    calculateAmount (percent) {
      return this.$n((this.item.product.price / 100 * percent).toFixed(this.currency.round))
    }
  }
}
