
import { mapGetters } from 'vuex'

import vueTelInputAllCountries from '@/mixins/vue-tel-input-all-countries'

export default {
  mixins: [
    vueTelInputAllCountries
  ],

  data () {
    return {
      form: {
        phone: {
          number: '',
          valid: true,
          country: undefined
        },
        login: '',
        password: '',
        remember: false,
        loading: false
      },
      error: '',
      show_password: false
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      compare: 'compare/compare',
      country: 'country',
      countries: 'countries'
    })
  },

  created () {
    this.form.phone.country = this.country
  },

  methods: {
    onPhoneInput (number, phone) {
      this.form.login = phone.number

      if (typeof phone.valid !== 'undefined' && phone.valid !== true) {
        this.form.phone.valid = false
      } else {
        this.form.phone.valid = true
      }
    },

    async submit () {
      if (!this.form.phone.valid) {
        return
      }

      this.form.loading = true

      await this.$auth.loginWith('laravelJWT', {
        data: {
          login: this.form.login,
          password: this.form.password,
          remember: this.form.remember,
          cart: this.cart.id ? this.cart : null,
          compare: this.compare.id ? this.compare : null,
          country: this.country
        }
      })
        .then(() => {
          while (!this.$auth.loggedIn) {
            // Just in case auth not set
          }

          let compareSynced = this.compare.items.concat(this.$auth.user.compare.items)
          const compareSeen = Object.create(null)
          compareSynced = [...new Set([...this.compare.items, ...this.$auth.user.compare.items])].filter((o) => {
            const key = ['class', 'compareable_id'].map(k => o[k]).join('|')
            if (!compareSeen[key]) {
              compareSeen[key] = true
              return true
            }
          })

          this.$store.dispatch('compare/setCompare', {
            id: this.$auth.user.compare.id,
            items: compareSynced
          })

          this.$store.dispatch('compare/syncCompare')

          let cartSynced = this.cart.items.concat(this.$auth.user.cart.items)
          const cartSeen = Object.create(null)
          cartSynced = [...new Set([...this.cart.items, ...this.$auth.user.cart.items])].filter((o) => {
            const key = ['class', 'cartable_id'].map(k => o[k]).join('|')
            if (!cartSeen[key]) {
              cartSeen[key] = true
              return true
            }
          })

          this.$store.dispatch('cart/setCart', {
            id: this.$auth.user.cart.id,
            items: cartSynced
          })

          this.$store.dispatch('cart/syncCart')

          this.$emit('modalActiveToggle', false)
        })
        .catch((e) => {
          // console.log(e)
          // this.error = e.response.data.message || this.$t('pages.error.other')
        })
        .finally((e) => {
          this.form.loading = false
        })
    },

    social (driver) {
      this.$nextTick(() => {
        this.$auth.loginWith(driver)
      })
    }
  }
}
