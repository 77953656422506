
import { mapGetters } from 'vuex'

export default {
  props: {
    hiddenMobile: {
      required: true,
      default () {
        return true
      },
      type: Boolean
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      cartModal: 'cart/modal'
    }),

    quantity () {
      return this.cart.items.reduce((quantity, item) => quantity + item.quantity, 0)
    }
  }
}
